<template>
    <modal ref="modalProgramarEnvio" titulo="Programar envío" no-aceptar adicional="Programar" @adicional="programarLanzamiento()">
        <div class="row mx-0 justify-center">
            <div class="col-10 text-general f-15">
                Seleccionar fecha y hora de envío
            </div>
        </div>
        <div class="row mx-0 justify-center mt-3">
            <div class="col-5">
                <el-date-picker
                v-model="fecha"
                type="date"
                size="small"
                class="w-100"
                format="dd MMM yyyy"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                @change="onChangeDate()"
                />
            </div>
            <div class="col-5">
                <el-time-select
                v-model="hora"
                size="small"
                :picker-options="pickerOptionsTime"
                />
            </div>
            <div class="col-10 mt-3">
                <el-checkbox v-model="inmediato" class="check-dark">
                    Envio inmediato
                </el-checkbox>
            </div>
        </div>
    </modal>
</template>

<script>
import moment from 'moment';
import notificaciones from '~/services/publicidad/notificaciones';

export default {
    data(){
        return {
            fecha: '',
            hora: '',
            inmediato: false,
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() < moment().subtract(1,'days');
                }
            },
        }
    },
    computed: {
        esHoy(){
            return moment(this.fecha, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'));
        },
        pickerOptionsTime(){
            return {
                start: this.esHoy ? this.dateTime() : '00:00',
                step: '00:30',
                end: '24:00',
            }
        }
    },
    methods: {
        toggle(){
            this.limpiar()
            this.$refs.modalProgramarEnvio.toggle();
        },
        async programarLanzamiento(){
            try {
                if((!this.fecha || !this.hora) && !this.inmediato) return this.notificacion('', 'Debe seleccionar la fecha y la hora', 'error')
                let model = {
                    inmediato: this.inmediato,
                    fecha: `${this.fecha} ${this.hora}`
                }
                const { data } = await notificaciones.programarLanzamiento(model, this.$route.params.id_lanzamiento);
                if (data.exito){
                    this.notificacion('', 'Guardado correctamente', 'success')                    
                    this.$emit('saved')
                    this.toggle()
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        dateTime(){
            const start = moment();
            const remainder = 30 - (start.minute() % 30);
     
            const dateTime = moment(start).add(remainder, "minutes").format("HH:mm");
            return dateTime
        },
        onChangeDate(){
            if ((this.hora && this.fecha) && this.esHoy){
                let esMenor = moment(`${this.fecha} ${this.hora}`).isSameOrBefore(`${this.fecha} ${this.dateTime()}`)
                if (esMenor) this.hora = ''
            }
        },
        limpiar(){
            this.fecha = ''
            this.hora = ''
            this.inmediato = false
        }
    }
}
</script>